import { Component, OnInit } from '@angular/core';

import { DashService } from '../dash.service';
import { MessageService } from '../message.service';
import { ProfileService } from '../profile.service';
import { QueryMetaData } from '../query-metadata';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  procDefCount: number;
  taskCount: number;

  constructor(private dashService: DashService,
              private messageService: MessageService,
              private profileService: ProfileService) { }

  ngOnInit(): void {
    this.messageService.set(`Welcome back ${this.profileService.getFirstName()}!`);
    this.getDashData();
  };

  getDashData(): void {
    this.dashService.getTaskCount()
        .subscribe(x => this.taskCount = x);
    this.dashService.getProcessDefinitionCount()
        .subscribe(x => this.procDefCount = x);
  }

}
