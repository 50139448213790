import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { MessageService } from '../message.service';
import { Task } from '../task';
import { TaskService } from '../task.service';
import { FormDefinition } from '../form-definition';
import { FieldDefinition } from '../field-definition';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent {

  @Input()
  formElId: string;

  @Input()
  form: FormDefinition;

  @Output()
  change = new EventEmitter<FieldDefinition>();

  onBlurField(evt: FocusEvent): void {
    let trgt = evt.target as HTMLInputElement;
    if (trgt.checkValidity()) {
      trgt.classList.remove('govuk-input--error');
      let container = document.getElementById(trgt.id).closest('.govuk-form-group');
      container.classList.remove('govuk-form-group--error');
      if (container.querySelector('.govuk-error-message'))
        container.querySelector('.govuk-error-message').classList.add('govuk-visually-hidden');
      // console.info(' saving: '+val+' to '+trgt.id+' of '+task.id);
      let field = this.form.fields.find(f => f.id === trgt.id);
      if (evt.target['type'] === 'checkbox') field.value = trgt.checked;
      // this.change.emit(field);
    } else {
      trgt.classList.add('govuk-input--error');
      let container = document.getElementById(trgt.id).closest('.govuk-form-group');
      container.classList.add('govuk-form-group--error');
      if (container.querySelector('.govuk-visually-hidden'))
        container.querySelector('.govuk-visually-hidden').classList.remove('govuk-visually-hidden');
      container.querySelector('.govuk-error-message').innerHTML = trgt.validationMessage;
    }
  }
}
