import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './dashboard/dashboard.component';
import { ProcessStartComponent } from './process-start/process-start.component';
import { ProcessStartDetailComponent } from './process-start-detail/process-start-detail.component';
import { TaskDetailComponent } from './task-detail/task-detail.component';
import { TaskListComponent } from './task-list/task-list.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'dashboard'
  }, {
    path: 'dashboard',
    component: DashboardComponent
  }, {
    path: 'tasks',
    component: TaskListComponent
  }, {
    path: 'tasks/task/:id',
    component: TaskDetailComponent
  }, {
    path: 'process-starters',
    component: ProcessStartComponent
  }, {
    path: 'process-starters/detail/:id',
    component: ProcessStartDetailComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
