import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

import { FormDefinition } from '../form-definition';
import { MessageService } from '../message.service';
import { ProcessDefinition } from '../process-definition';
import { ProcessInstance } from '../process-instance';
import { RepoService } from '../repo.service';
import { ProfileService } from '../profile.service';

@Component({
  selector: 'app-process-start-detail',
  templateUrl: './process-start-detail.component.html',
  styleUrls: ['./process-start-detail.component.scss']
})
export class ProcessStartDetailComponent implements OnInit {

  form: FormDefinition;
  filledFormDef: FormDefinition;
  procInst: ProcessInstance;
  procDefId: string;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private profileService: ProfileService,
              private repoService: RepoService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.messageService.set(`Starting process...`);
    this.procDefId = this.route.snapshot.paramMap.get('id');
    this.repoService.getFormDefinition(this.procDefId)
        .subscribe(form => this.form = form);
  }

  onStart(form: FormDefinition): void {
    // a bit of magic here: use taskId as form id rendered by FormComponent
    let trgt = document.getElementById(this.form.id) as HTMLFormElement;
    if (trgt.checkValidity()) {
      this.filledFormDef = form;
      for (let idx = 0; idx < this.filledFormDef.fields.length; idx++) {
        const field = this.filledFormDef.fields[idx];
        if (field.type == 'dropdown') {
          // form needs to send option id not name
          field.value = field.options.filter(x => x.name == field.value)[0].id;
        }
      }

      this.messageService.set(`Submitting ${form.key}`);
      let bizKey = this.procDefId.substring(0,this.procDefId.indexOf(':'));
      bizKey = `${form.name} from ${this.profileService.getUsername()}`;
      this.repoService
        .startInstance(this.procDefId, bizKey, this.filledFormDef)
        .subscribe(procInst => {
          this.procInst = procInst;
          this.router.navigate(['/process-starters']);
        });
    } else {
      this.messageService.set(`Cannot submit while one or more responses are invalid...`, 'error');
      document.querySelectorAll(':invalid').forEach(d => {
        d.dispatchEvent(new MouseEvent('blur', {
          view: window,
          bubbles: true,
          cancelable: true
        }));
      });
    }
  }
}
