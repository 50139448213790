<datalist id="group-list">
  <option value="admin">
  <option value="office">
  <option value="support">
</datalist>

<form *ngIf="form" [id]="formElId">
  <div *ngFor="let field of form.fields" class="govuk-form-group">
    <fieldset class="govuk-fieldset">
      <div *ngIf="field.type === 'dropdown'">
        <datalist id="{{field.id}}-list">
          <div *ngFor="let option of field.options">
            <option [value]="option.name">
          </div>
        </datalist>
      </div>

      <span [ngSwitch]="field.type">
        <span *ngSwitchCase="'headline-with-line'"></span>
        <span *ngSwitchCase="'headline'"></span>
        <span *ngSwitchCase="'horizontal-line'"></span>
        <span *ngSwitchCase="'hyperlink'">
          <label class="govuk-label">{{field.name}} <span *ngIf="field.required">*</span></label>
          <span class="govuk-hint">
            {{field.placeholder == undefined ? '' : field.placeholder}}
          </span>
        </span>
        <span *ngSwitchCase="'upload'">
          <label class="govuk-label">{{field.name}} <span *ngIf="field.required">*</span></label>
          <span class="govuk-hint">
            {{field.placeholder == undefined ? '' : field.placeholder}}
          </span>
        </span>
        <span *ngSwitchDefault>
          <label class="govuk-label">{{field.name}} <span *ngIf="field.required">*</span></label>
          <span class="govuk-hint">
            {{field.placeholder == undefined ? '' : field.placeholder}}
          </span>
        </span>
      </span>
      <span id="{{field.id}}-error" class="govuk-visually-hidden govuk-error-message"> </span>
      <span [ngSwitch]="field.type">
        <div *ngSwitchCase="'boolean'" class="govuk-form-group">
          <div class="govuk-checkboxes" data-module="govuk-checkboxes">
            <div class="govuk-checkboxes__item">
              <input class="govuk-checkboxes__input"
                      [id]="field.id"
                      [disabled]="field.readOnly ? 'disabled' : null"
                      [checked]="field.value"
                      [readonly]="field.readOnly ? 'readonly' : null"
                      required="{{field.required ? 'required': ''}}"
                      type="checkbox"
                      (input)="field.value=$event.target.checked"
                      (blur)="onBlurField($event)">
              <label class="govuk-label govuk-checkboxes__label" [for]="field.id"> {{ field.value == true ? 'Yes' : 'No' }} </label>
            </div>
          </div>
        </div>
        <input *ngSwitchCase="'decimal'" class="govuk-input govuk-input--width-{{field.params == undefined ? '20' : field.params.maxLength == undefined ? '20' : field.params.maxLength}}"
              [id]="field.id"
              [disabled]="field.readOnly ? 'disabled' : null"
              pattern="[0-9]*\.[0-9]*"
              [readonly]="field.readOnly ? 'readonly' : null"
              [required]="field.required ? 'required' : null"
              type="numeric"
              [value]="field.value"
              (input)="field.value=$event.target.value"
              (blur)="onBlurField($event)">
        <div *ngSwitchCase="'expression'" [innerHtml]="field.value"></div>
        <h1 *ngSwitchCase="'headline-with-line'" class="govuk-heading-l">{{field.name}}</h1>
        <h2 *ngSwitchCase="'headline'" class="govuk-heading-m">{{field.name}}</h2>
        <hr *ngSwitchCase="'horizontal-line'">
        <span *ngSwitchCase="'hyperlink'">
          <div *ngIf="!field.readOnly">
          <input class="govuk-input govuk-input--width-{{field.params == undefined ? '20' : field.params.maxLength == undefined ? '20' : field.params.maxLength}}"
                [id]="field.id"
                required="{{field.required ? 'required': ''}}"
                type="url"
                [value]="field.value"
                (input)="field.value=$event.target.value"
                (blur)="onBlurField($event)">
          </div>
          <a *ngIf="field.value != undefined && field.value != ''" [href]="field.value">{{field.name}}</a>
        </span>
        <textarea *ngSwitchCase="'multi-line-text'" class="govuk-textarea"
              [id]="field.id"
              [disabled]="field.readOnly ? 'disabled' : null"
              [readonly]="field.readOnly ? 'readonly' : null"
              [required]="field.required ? 'required' : null"
              rows="3"
              [value]="field.value"
              (input)="field.value=$event.target.value"
              (blur)="onBlurField($event)"></textarea>
        <input *ngSwitchCase="'integer'" class="govuk-input govuk-input--width-{{field.params == undefined ? '20' : field.params.maxLength == undefined ? '20' : field.params.maxLength}}"
              [id]="field.id"
              [disabled]="field.readOnly ? 'disabled' : null"
              pattern="[0-9]*"
              [readonly]="field.readOnly ? 'readonly' : null"
              [required]="field.required ? 'required' : null"
              type="numeric"
              [value]="field.value"
              (input)="field.value=$event.target.value"
              (blur)="onBlurField($event)">
        <input *ngSwitchCase="'people'" class="govuk-input govuk-input--width-{{field.params == undefined ? '20' : field.params.maxLength == undefined ? '20' : field.params.maxLength}}"
              [id]="field.id"
              [disabled]="field.readOnly ? 'disabled' : null"
              [readonly]="field.readOnly ? 'readonly' : null"
              [required]="field.required ? 'required' : null"
              type="email"
              [value]="field.value"
              (input)="field.value=$event.target.value"
              (blur)="onBlurField($event)">
        <div *ngSwitchCase="'radio-buttons'" class="govuk-form-group">
          <div class="govuk-radios">
            <div *ngFor="let option of field.options" class="govuk-radios__item">
              <input *ngIf="field.value !== option.name" class="govuk-radios__input"
                [id]="field.id" type="radio"
                [name]="field.id" [value]="option.name"
                (blur)="onBlurField($event)">
              <input *ngIf="field.value === option.name" class="govuk-radios__input"
                [id]="field.id" type="radio"
                checked
                [name]="field.id" [value]="option.name"
                (blur)="onBlurField($event)">
              <label class="govuk-label govuk-radios__label" [for]="field.id">
                {{option.name}}
              </label>
            </div>
          </div>
        </div>
        <input *ngSwitchCase="'upload'" class="govuk-input govuk-input--width-{{field.params == undefined ? '20' : field.params.maxLength == undefined ? '20' : field.params.maxLength}}"
              [id]="field.id"
              [disabled]="field.readOnly ? 'disabled' : null"
              [readonly]="field.readOnly ? 'readonly' : null"
              [required]="field.required ? 'required' : null"
              type="file"
              [value]="field.value"
              (input)="field.value=$event.target.value">
        <input *ngSwitchDefault class="govuk-input govuk-input--width-{{field.params == undefined ? '20' : field.params.maxLength == undefined ? '20' : field.params.maxLength}}"
              [id]="field.id"
              [disabled]="field.readOnly ? 'disabled' : null"
              [readonly]="field.readOnly ? 'readonly' : null"
              [required]="field.required ? 'required' : null"
              [type]="field.type"
              [attr.list]="field.id+'-list'"
              [value]="field.value"
              (input)="field.value=$event.target.value"
              (blur)="onBlurField($event)">
      </span>
    </fieldset>
  </div>
</form>
