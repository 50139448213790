<div *ngIf="messageService.messages.length" [ngSwitch]="messageService.level">
  <div *ngSwitchCase="'error'" class="govuk-error-summary" aria-labelledby="error-summary-title" role="alert" tabindex="-1" data-module="govuk-error-summary">
    <h2 class="govuk-error-summary__title" id="error-summary-title">
      There is a problem
    </h2>
    <div class="govuk-error-summary__body">
      <ul class="govuk-list govuk-error-summary__list">
        <div *ngFor='let message of messageService.messages'>
          <li> {{message}} </li>
        </div>
      </ul>
    </div>
  </div>
  <div *ngSwitchDefault class="messages govuk-inset-text">
    <span *ngFor='let message of messageService.messages'> {{message}} </span>
    <!--button class="clear"
        (click)="messageService.clear()">clear</button-->
  </div>
</div>
