import { Injectable } from '@angular/core';

import { KeycloakProfile } from 'keycloak-js';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  profile: KeycloakProfile;
  token: string;

  constructor() {
    this.profile = JSON.parse(localStorage['ang-profile']);
    this.token = JSON.parse(localStorage['ang-profile']).attributes.token;
  }

  set(profile: KeycloakProfile) {
    this.profile = profile;
  }

  getEmail(): string {
    return this.profile.email;
  }
  getFirstName(): string {
    return this.profile.firstName;
  }
  getLastName(): string {
    return this.profile.lastName;
  }
  getToken(): string {
    return this.token;
  }
  getUsername(): string {
    return this.profile.username;
  }
  logout() {
    // throw new Error("Method not implemented.");
  }
}
