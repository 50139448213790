import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';


import { MessageService } from '../message.service';
import { Task } from '../task';
import { TaskService } from '../task.service';
import { FormDefinition } from '../form-definition';
import { Form } from '@angular/forms';
import { FieldDefinition } from '../field-definition';

@Component({
  selector: 'app-task-detail',
  templateUrl: './task-detail.component.html',
  styleUrls: ['./task-detail.component.scss']
})
export class TaskDetailComponent implements OnInit {

  taskId: string;
  task: Task;
  taskForm: FormDefinition;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private taskService: TaskService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.messageService.set(`Showing task ...`);
    this.taskId = this.route.snapshot.paramMap.get('id');
    this.taskService.getTask(this.taskId)
        .subscribe(task => this.task = task);
    this.taskService.getTaskForm(this.taskId)
        .subscribe(taskForm => this.taskForm = taskForm);
  }

  onClaim(task: Task): void {
    this.messageService.set(`Claiming task ${task.id} ...`);
    this.taskService
      .claim(task)
      .subscribe(_ => this.router.navigate(['/tasks']));
  }

  onComplete(evt: FocusEvent): void {
    // a bit of magic here: use taskId as form id rendered by FormComponent
    let trgt = document.getElementById(this.taskId) as HTMLFormElement;
    if (trgt.checkValidity()) {
      this.messageService.set(`Completing task ${this.task.id} ...`);
      console.info(' submitting: '+JSON.stringify(this.taskForm));
      this.taskService
        .complete(this.task)
        .subscribe(_ => this.router.navigate(['/tasks']));
    } else {
      this.messageService.set(`Cannot complete while one or more responses are invalid...`, 'error');
      document.querySelectorAll(':invalid').forEach(d => {
        d.dispatchEvent(new MouseEvent('blur', {
          view: window,
          bubbles: true,
          cancelable: true
        }));
      });
    }
  }

  onSaveField(evt: FocusEvent) {
    let trgt = evt.target as HTMLFormElement;
    if (trgt.checkValidity()) {
      this.messageService.set(`Saving field ${trgt.id} ...`);
      this.taskService
        .saveField(this.taskId, { name: trgt.id, value: trgt.value })
        .subscribe(_ => { });
    } else {
      this.messageService.set(`Field ${trgt.name} is invalid...`, 'error');
    }
  }
}
