import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';

import { MessageService } from './message.service';
import { DiscoveryService } from './discovery-service';
import { PaginationService } from './pagination.service';
import { ProfileService } from './profile.service';
import { QueryMetaData } from './query-metadata';
import { Task } from './task';
import { TaskResponse } from './task-response';
import { TASKS } from './mock-tasks';
import { FormDefinition } from './form-definition';
import { Variable } from './variable';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  private dateOptions: Intl.DateTimeFormatOptions = {
    day: "numeric", month: "short", year: "numeric",
    hour: "2-digit", minute: "2-digit"
  };

  constructor(
      private discoveryService: DiscoveryService,
      private profileService: ProfileService,
      private http: HttpClient,
      private paginationService: PaginationService,
      private messageService: MessageService) { }

  /** Log a TaskService message with the MessageService */
  private log(message: string) {
    this.messageService.set(`${message}`);
  }

  /** Log a TaskService error with the MessageService */
  private error(message: string) {
    this.messageService.set(`${message}`, 'error');
  }

  getTasks(query: QueryMetaData): Observable<Task[]> {
    return this.http.get<TaskResponse>( this.discoveryService.getTaskListUrl(query), {headers: this.getHttpHeaders()} )
      .pipe(
        tap(x => this.log(`Found ${x.total} task${x.total > 1 ? 's' : ''} suitable for you`)),
        tap(x => this.paginationService.paginate('/tasks', { total: x.total, start: x.start, size: query.size} as QueryMetaData)),
        map(x => x.data), /* flowable response includes metadata, just want data */
        catchError(this.handleError<Task[]>('getTasks', []))
      );
    //return of(TASKS);
  }

  getTask(taskId: string): Observable<Task> {
    this.log(`Showing task ${taskId}`);
    return this.http.get<Task>(  this.discoveryService.getTaskUrl(taskId), {headers: this.getHttpHeaders()} )
      .pipe(
        // tslint:disable-next-line: max-line-length
        tap(x => this.log(`Found task created ${new Date(x.createTime).toLocaleDateString(navigator.language, this.dateOptions)}${x.dueDate === null ? '' : ' and due by ' + new Date(x.dueDate).toLocaleDateString(navigator.language, this.dateOptions)}: ${x.name}`)),
        catchError(this.handleError<Task>('getTask', undefined))
      );
  }
  getTaskForm(taskId: string): Observable<FormDefinition> {
    return this.http.get<FormDefinition>(  this.discoveryService.getTaskFormUrl(taskId), {headers: this.getHttpHeaders()} )
      .pipe(
        catchError(this.handleError<FormDefinition>('getTaskForm', undefined))
      );
  }

  claim(task: Task): Observable<Task> {
    this.log(`Claiming task ${task.id}`);
    const taskAction = { action: 'claim', assignee : this.profileService.getUsername() };
    return this.http.post<Task>( this.discoveryService.getTaskUrl(task.id), taskAction, {headers: this.getHttpHeaders()} )
      .pipe(
        tap(_ => this.log(`Claimed task '${task.name}'`)),
        catchError(this.handleError<Task>('claim', task))
      );
  }

  complete(task: Task): Observable<Task> {
    this.log(`Completing task ${task.id}`);
    const taskAction = { action: 'complete' };
    return this.http.post<Task>( this.discoveryService.getTaskUrl(task.id), taskAction, {headers: this.getHttpHeaders()} )
      .pipe(
        tap(_ => this.log(`Completed task '${task.name}'`)),
        catchError(this.handleError<Task>('complete', task))
      );
  }

  createField(taskId: string, field: Variable) {
    return this.http.post<Variable>( this.discoveryService.getTaskVariablesCreateUrl(taskId), [ field ], {headers: this.getHttpHeaders()} )
      .pipe(
        tap(_ => this.log(`Created field '${field.name}'`)),
        catchError(this.handleError<Variable>('createField', field))
      );
    };

  saveField(taskId: string, field: Variable) {
    field.scope = 'global';
    field.taskId = taskId;
    return this.http.put<Variable>( this.discoveryService.getTaskVariablesUpdateUrl(taskId, field.name), field, {headers: this.getHttpHeaders()} )
      .pipe(
        tap(_ => this.log(`Saved field '${field.name}'`)),
        catchError(this.handleError<Variable>('saveField', field))
      );
  }

  private getHttpHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    return headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      switch(operation) {
      case 'saveField':
        console.warn('unable to save field, try creating...');
        let v = <Variable>(result as unknown);
        this.createField(v.taskId, v)
          .subscribe(x => { return of(x); });
        break;
      case 'getTaskForm':
        this.messageService.set("Warning: Cannot find a form for this task. "
          + "This is typically used to prompt you to do something outside the system. "
          + "Click 'Complete' to notify others downstream when you're done.");
        // intentionally no break;
      default:
        // TODO: send the error to remote logging infrastructure
        console.error(operation+': '+error.message); // log to console instead

        // TODO: better job of transforming error for user consumption
        this.error(`${operation} failed: ${error.message}`);

        // Let the app keep running by returning an empty result.
        return of(result as T);
      }
    };
  }
}
