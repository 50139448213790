import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { catchError } from 'rxjs/internal/operators/catchError';
import { map } from 'rxjs/internal/operators/map';
import { tap } from 'rxjs/internal/operators/tap';
import { DiscoveryService } from './discovery-service';
import { MessageService } from './message.service';
import { ProfileService } from './profile.service';
import { QueryMetaData } from './query-metadata';
import { TaskResponse } from './task-response';

@Injectable({
  providedIn: 'root'
})
export class DashService {
  procDefCount: number;
  taskCount: number;

  constructor(
      private discoveryService: DiscoveryService,
      private http: HttpClient,
      private messageService: MessageService,
      private profileService: ProfileService) { }

  /** Log a DashService message with the MessageService */
  private log(message: string) {
    this.messageService.set(`${message}`);
  }

  /** Log a DashService error with the MessageService */
  private error(message: string) {
    this.messageService.set(`${message}`, 'error');
  }

  getProcessDefinitionCount(): Observable<number> {
    let start = 0;
    let size = 1;
    let query = { start, size } as QueryMetaData;
    return this.http.get<TaskResponse>( this.discoveryService.getProcessDefinitionsUrl(query), {headers: this.getHttpHeaders()} )
      .pipe(
        map(x => x.total),
        catchError(this.handleError<number>('getProcessDefinitionCount', 0))
      );
  }

  getTaskCount(): Observable<number> {
    let start = 0;
    let size = 1;
    let query = { start, size } as QueryMetaData;
    return this.http.get<TaskResponse>( this.discoveryService.getTaskListUrl(query), {headers: this.getHttpHeaders()} )
      .pipe(
        map(x => x.total),
        catchError(this.handleError<number>('getTaskCount', 0))
      );
  }

  private getHttpHeaders(): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('Content-Type', 'application/json');
    return headers.append('Authorization', 'Basic ' + btoa(this.profileService.getUsername() + ':' + this.profileService.getToken()));
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
