import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../environments/environment';

import { MessageService } from '../message.service';
import { QueryMetaData } from '../query-metadata';
import { Task } from '../task';
import { TaskService } from '../task.service';

@Component({
  selector: 'app-task-list',
  templateUrl: './task-list.component.html',
  styleUrls: ['./task-list.component.scss']
})
export class TaskListComponent implements OnInit {

  tasks: Task[];
  selectedTask: Task;
  query: QueryMetaData;

  constructor(private route: ActivatedRoute,
              private taskService: TaskService,
              private messageService: MessageService) { }

  ngOnInit(): void {
    this.route.queryParamMap
      .subscribe((params) => {
        // tslint:disable-next-line: radix
        const start = params.get('start') === null ? 0 : parseInt(params.get('start'));
        // tslint:disable-next-line: radix
        const size = params.get('size') === null ? environment.pageSize : parseInt(params.get('size'));
        this.query = { start, size } as QueryMetaData;
        this.getTasks(this.query);
      }
    );
  }

  getTasks(query: QueryMetaData): void {
    this.taskService.getTasks(query)
        .subscribe(tasks => this.tasks = tasks);
  }

  onSelect(task: Task): void {
    this.selectedTask = task;
  }
}
