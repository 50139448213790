import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  level: string;
  messages: string[] = [];

  add(message: string) {
    this.messages.push(message);
  }

  clear() {
    this.messages = [];
  }

  set(message: string, level: string = 'info') {
    this.clear();
    this.level = level;
    this.add(message);
  }
}
