  <script>
    document.body.className = ((document.body.className) ? document.body.className + ' js-enabled' : 'js-enabled');
  </script>

  <a href="#main-content" class="govuk-skip-link">Skip to main content</a>

  <header class="govuk-header " role="banner" data-module="govuk-header">
    <div class="govuk-header__container govuk-width-container">
      <div class="govuk-header__logo">
        <a *ngIf="env.contentLinks.home" href="{{env.contentLinks.home}}" class="govuk-header__link govuk-header__link--homepage">
          <span class="govuk-header__logotype">
            <img focusable="false" class="govuk-header__logotype-crown" src="{{env.contentLinks.logo}}">
            <span *ngIf="env.contentLinks.logoText" class="govuk-header__logotype-text"> {{env.contentLinks.logoText}} </span>
          </span>
        </a>
      </div>
      <button class="govuk-button logout" data-module="govuk-button" (click)="onLogout()"> Logout </button>
    </div>
  </header>

  <main class="govuk-main-wrapper govuk-clearfix" id="main-content" role="main">
    <div class="govuk-width-container ">
      <div class="govuk-phase-banner">
        <p class="govuk-phase-banner__content">
          <strong class="govuk-tag govuk-phase-banner__content__tag">
            alpha
          </strong>
          <span class="govuk-phase-banner__text">
            This is a new service – your <a class="govuk-link" href="{{env.contentLinks.feedback}}">feedback</a> will help us to improve it.
          </span>
        </p>
      </div>

      <nav class="app-navigation govuk-clearfix">
        <ul class="app-navigation__list app-width-container">
          <li class="app-navigation__list-item">
            <a class="govuk-link govuk-link--no-visited-state app-navigation__link" href="/tasks" data-topnav="Complete existing work"
               routerLink="/tasks" routerLinkActive="app-navigation__list-item--current">Complete existing work</a>
          </li>
          <li class="app-navigation__list-item">
            <a class="govuk-link govuk-link--no-visited-state app-navigation__link" href="/process-starters" data-topnav="Start new work"
               routerLink="/process-starters" routerLinkActive="app-navigation__list-item--current">Start new work</a>
          </li>
        </ul>
      </nav>

      <app-messages></app-messages>
      <router-outlet></router-outlet>
    </div>
  </main>

  <!-- Footer -->
  <footer class="govuk-footer " role="contentinfo">
    <div class="govuk-width-container ">
      <div class="govuk-footer__meta">
        <div class="govuk-footer__meta-item govuk-footer__meta-item--grow">
          <h2 class="govuk-visually-hidden">Support links</h2>
          <ul class="govuk-footer__inline-list">
            <li *ngIf="env.contentLinks.help" class="govuk-footer__inline-list-item">
              <a class="govuk-footer__link" href="{{env.contentLinks.help}}"> Help </a>
            </li>
            <li *ngIf="env.contentLinks.privacy" class="govuk-footer__inline-list-item">
              <a class="govuk-footer__link" href="{{env.contentLinks.privacy}}"> Privacy </a>
            </li>
            <li *ngIf="env.contentLinks.contact" class="govuk-footer__inline-list-item">
              <a class="govuk-footer__link" href="{{env.contentLinks.contact}}"> Contact </a>
            </li>
            <li *ngIf="env.contentLinks.terms" class="govuk-footer__inline-list-item">
              <a class="govuk-footer__link" href="{{env.contentLinks.terms}}"> Terms and Conditions </a>
            </li>
          </ul>

          <span *ngIf="env.contentLinks.licence" class="govuk-footer__licence-description">
            Available under <a class="govuk-footer__link" href="{{env.contentLinks.licence}}" rel="licence">{{env.contentLinks.licenceText}}</a>, except where otherwise stated.
          </span>
        </div>
        <div *ngIf="env.contentLinks.copyright" class="govuk-footer__meta-item">
          <a class="govuk-footer__link govuk-footer__copyright-logo" href="{{env.contentLinks.copyright}}">{{env.contentLinks.copyrightText}}</a>
        </div>
      </div>
    </div>
  </footer>
