<h1 class="govuk-heading-l">Digital service hub</h1>

<p class="govuk-body">Use this service to:</p>

<ul class="govuk-list govuk-list--bullet">
  <li>view service workload and status</li>
  <li>start and delegate new workflows</li>
  <li>perform your part in work started by someone else</li>
</ul>

<div class="govuk-grid-row">
  <div class="govuk-grid-column-one-half">
    <div class="govuk-panel govuk-panel--wip">
      <h1 class="govuk-panel__title">
        Work in progress
      </h1>
      <div class="govuk-panel__body">
        You have <strong>{{taskCount}}</strong> tasks to work on
      </div>
      <a href="/tasks" role="button" draggable="false" class="govuk-button govuk-button--start" data-module="govuk-button"
         routerLink="/tasks" routerLinkActive="app-navigation__list-item--current"> Start now
        <svg class="govuk-button__start-icon" xmlns="http://www.w3.org/2000/svg" width="17.5" height="19" viewBox="0 0 33 40" aria-hidden="true" focusable="false">
          <path fill="currentColor" d="M0 0h13l20 20-20 20H0l20-20z" />
        </svg>
      </a>
    </div>
  </div>
  <div class="govuk-grid-column-one-half">
    <div class="govuk-panel govuk-panel--services">
      <h1 class="govuk-panel__title">
        Services available
      </h1>
      <div class="govuk-panel__body">
        You may start <strong>{{procDefCount}}</strong> new services
      </div>
      <a href="/process-starters" role="button" draggable="false" class="govuk-button govuk-button--start" data-module="govuk-button"
         routerLink="/process-starters" routerLinkActive="app-navigation__list-item--current"> Start now
        <svg class="govuk-button__start-icon" xmlns="http://www.w3.org/2000/svg" width="17.5" height="19" viewBox="0 0 33 40" aria-hidden="true" focusable="false">
          <path fill="currentColor" d="M0 0h13l20 20-20 20H0l20-20z" />
        </svg>
      </a>
    </div>
  </div>
  <!--<strong>212</strong> workflows in progress<br>-->
</div>
