import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { environment } from '../../environments/environment';

import { MessageService } from '../message.service';
import { ProcessDefinition } from '../process-definition';
import { ProcessInstance } from '../process-instance';
import { QueryMetaData } from '../query-metadata';
import { RepoService } from '../repo.service';

@Component({
  selector: 'app-process-start',
  templateUrl: './process-start.component.html',
  styleUrls: ['./process-start.component.scss']
})
export class ProcessStartComponent implements OnInit {

  procDefs: ProcessDefinition[];
  selectedProcDef: ProcessDefinition;
  procInst: ProcessInstance;
  query: QueryMetaData;

  constructor(private repoService: RepoService,
              private route: ActivatedRoute,
              private messageService: MessageService) { }

  ngOnInit(): void {
        this.route.queryParamMap
      .subscribe((params) => {
        // tslint:disable-next-line: radix
        const start = params.get('start') === null ? 0 : parseInt(params.get('start'));
        // tslint:disable-next-line: radix
        const size = params.get('size') === null ? environment.pageSize : parseInt(params.get('size'));
        this.query = { start, size } as QueryMetaData;
        this.getProcessDefinitions(this.query);
      }
    );
  }

  getProcessDefinitions(query: QueryMetaData): void {
    this.repoService.getProcessDefinitions(query)
        .subscribe(procDefs => this.procDefs = procDefs);
  }

  /** Start process instance without any inputs (no start form) */
  onSelect(procDef: ProcessDefinition): void {
    this.selectedProcDef = procDef;
    this.messageService.set(`Process definition selected id=${procDef.id}`);
    let bizKey = procDef.id.substring(0, procDef.id.indexOf(':'));
    this.repoService
      .startInstance(procDef.id, bizKey)
      .subscribe(procInst => this.procInst = procInst);
  }
}
