<section *ngIf="task" class="task-detail">

  <legend class="govuk-fieldset__legend govuk-fieldset__legend">
    <h2 class="govuk-heading-l">{{task.name}}</h2>
  </legend>

  <app-form [formElId]="taskId" [form]="taskForm"
            (change)="onSaveField($event)"></app-form>

  <button *ngIf="!task.assignee" class="govuk-button" data-module="govuk-button"
          (click)="onClaim(task)">
    Claim
  </button>
  <button *ngIf="task.assignee" class="govuk-button" data-module="govuk-button"
          (click)="onComplete($event)">
    Complete
  </button>
</section>
