import { Injectable } from '@angular/core';

import { environment } from '../environments/environment';

import { ProfileService } from './profile.service';
import { QueryMetaData } from './query-metadata';

@Injectable({
  providedIn: 'root',
})
export class DiscoveryService {
  constructor(private profileService: ProfileService) { }

  private formModelUrl = `${environment.server.url}/form-api/form/model`;
  private processInstancesUrl = `${environment.server.url}/service/runtime/process-instances`;

  getFormModelUrl(): string {
    return this.formModelUrl;
  }

  getProcessDefinitionsUrl(query: QueryMetaData): string {
    // tslint:disable-next-line: max-line-length
    return `${environment.server.url}/service/repository/process-definitions?startableByUser=${this.profileService.getUsername()}&latest=true&start=${query.start}&size=${query.size}`;
  }

  getProcessDefinitionUrl(procDefId: string): string {
    return `${environment.server.url}/${environment.server.processApi}/repository/process-definitions/${procDefId}`;
  }

  getProcessStartFormUrl(procDefId: string) : string {
    return `${environment.server.url}/service/repository/process-definitions/${procDefId}/start-form`;
  }

  getProcessInstancesUrl(): string {
    return this.processInstancesUrl;
  }

  getTaskListUrl(query: QueryMetaData): string {
    return `${environment.server.url}/service/runtime/tasks/?candidateOrAssigned=${this.profileService.getUsername()}&start=${query.start}&size=${query.size}`;
  }

  getTaskUrl(taskId: string): string {
    return `${environment.server.url}/service/runtime/tasks/${taskId}`;
  }

  getTaskFormUrl(taskId: string): string {
    return `${environment.server.url}/service/runtime/tasks/${taskId}/form`;
  }

  getTaskVariablesCreateUrl(taskId: string): string {
    return `${environment.server.url}/service/runtime/tasks/${taskId}/variables`;
  }

  getTaskVariablesUpdateUrl(taskId: string, varName: string): string {
    return `${environment.server.url}/service/runtime/tasks/${taskId}/variables/${varName}`;
  }

  getToken(): string {
    return this.profileService.getToken();
  }

  getUsername(): string {
    return this.profileService.getUsername();
  }

}
