<table class="govuk-table">
  <!--caption class="govuk-heading-l govuk-table__caption">Tasks</caption-->
  <thead class="govuk-table__head">
    <tr class="govuk-table__row">
      <th scope="col" class="govuk-table__header">Id</th>
      <th scope="col" class="govuk-table__header">Name</th>
      <th scope="col" class="govuk-table__header">Assignee</th>
      <th scope="col" class="govuk-table__header">Created</th>
      <th scope="col" class="govuk-table__header">Context</th>
      <th scope="col" class="govuk-table__header"></th>
    </tr>
  </thead>
  <tbody class="govuk-table__body">
    <tr *ngFor="let task of tasks; let i = index" class="govuk-table__row"
        [class.selected]="task === selectedTask"
        (click)="onSelect(task)">
      <td scope="row" class="govuk-table__header"><a [routerLink]="['/tasks/task', task.id]">{{i+1}}</a></td>
      <td class="govuk-table__cell">{{task.name}}</td>
      <td class="govuk-table__cell">{{task.assignee}}</td>
      <td class="govuk-table__cell">{{task.createTime | date:"dd MMM yy"}}<br/>{{task.createTime | date:"HH:mm"}}</td>
      <td class="govuk-table__cell">{{task.processDefinitionId == undefined ? '' : task.processDefinitionId.substring(0, task.processDefinitionId.lastIndexOf(':'))}}</td>
      <td class="govuk-table__cell">
        <button class="govuk-button" data-module="govuk-button" [routerLink]="['/tasks/task', task.id]">
          View
        </button>
      </td>
    </tr>
  </tbody>
</table>
<app-pagination></app-pagination>
