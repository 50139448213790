<table class="govuk-table">
  <!--caption class="govuk-heading-l govuk-table__caption">Process Definitions</caption-->
  <thead class="govuk-table__head">
    <tr class="govuk-table__row">
      <!--th scope="col" class="govuk-table__header">Id</th-->
      <th scope="col" class="govuk-table__header">Name</th>
      <!--th scope="col" class="govuk-table__header">Key</th-->
      <th scope="col" class="govuk-table__header">Description</th>
      <!--th scope="col" class="govuk-table__header">Version</th-->
      <!--th scope="col" class="govuk-table__header">Category</th-->
      <th scope="col" class="govuk-table__header">&nbsp;</th>
    </tr>
  </thead>
  <tbody class="govuk-table__body">
    <tr *ngFor="let procDef of procDefs" class="govuk-table__row"
        [class.selected]="procDef === selectedProcDef">
      <!--td scope="row" class="govuk-table__header">{{procDef.id}}</td-->
      <td class="govuk-table__cell">{{procDef.name}}</td>
      <!--td class="govuk-table__cell">{{procDef.key}}</td-->
      <td class="govuk-table__cell">{{procDef.description}}</td>
      <!--td class="govuk-table__cell">{{procDef.version}}</td-->
      <!--td class="govuk-table__cell">{{procDef.category}}</td-->
      <td *ngIf="procDef.startFormDefined" class="govuk-table__cell">
        <button class="govuk-button" data-module="govuk-button" [routerLink]="['/process-starters/detail', procDef.id]">
          Start
        </button>
      </td>
      <td *ngIf="!procDef.startFormDefined" class="govuk-table__cell">
        <button class="govuk-button" data-module="govuk-button" (click)="onSelect(procDef)">
          Start
        </button>
      </td>
    </tr>
  </tbody>
</table>
<app-pagination></app-pagination>
